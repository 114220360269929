<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-card-title>
              프로필
            </v-card-title>
            <v-divider/>
            <v-row class="my-2">
              <v-col cols="12" sm="12" class="align-self-center">
                <v-row align="center" justify="center" class="mr-0">
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-progress-circular
                        v-if="uploading"
                        :size="100"
                        :width="15"
                        :rotate="-90"
                        :value="progressUpload"
                        color="primary">
                        {{ progressUpload }}%
                      </v-progress-circular>

                      <v-avatar size="96" class="my-3" v-else>
                        <v-img
                          v-if="imageUrl" :src="imageUrl"
                        ></v-img>
                        <v-img
                          v-else
                        ><v-icon size="96" color="grey lighten-1">mdi-account-circle</v-icon></v-img>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="#036358"
                          >
                            <v-btn  type="button" @click="onClickImageUpload"><v-icon>mdi-camera</v-icon></v-btn>
                          </v-overlay>
                        </v-fade-transition>
                      </v-avatar>
                    </template>
                  </v-hover>
                </v-row>
                <image-uploader
                  hidden
                  ref="imageInput"
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="0"
                  :autoRotate="true"
                  outputFormat="verbose"
                  @input="setImage"
                  :maxWidth="200"
                  :quality="0.7"
                  doNotResize="['gif', 'svg']"
                ></image-uploader>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">아이디</v-list-item-subtitle>
                    <v-list-item-title>{{ $store.state.user.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">닉네임</v-list-item-subtitle>
                    <v-list-item-title>{{ $store.state.user.displayName ? $store.state.user.displayName : '미등록' }}</v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                    <v-btn tile dark color="primary" @click="changePw">패스워드 변경</v-btn>
                  </v-list-item-action> -->
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">본인확인</v-list-item-subtitle>
                    <v-list-item-title>미확인</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">회원구분</v-list-item-subtitle>
                    <v-list-item-title>무료회원</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>

</template>
<script>
import ImageUploader from 'vue-image-upload-resize'

export default {
  components: { ImageUploader },
  data () {
    return {
      imageUrl: '',
      progressUpload: 0,
      uploadTask: '',
      firebaseImageUrl: '',
      firebaseImageName: '',
      uploading: false,
      uploaded: false,
      hasImage: false,
      form: {}
    }
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.firebaseImageUrl = downloadURL
          this.uploading = false
          this.uploaded = true
          // this.$emit('downloadURL', downloadURL)
          this.updatePhoto()
        })
      })
    }
  },
  created () {
    this.read()
  },
  methods: {
    onClickImageUpload () {
      this.$refs.imageInput.$el.children[1].click()
    },
    setImage (f) {
      this.hasImage = true
      this.imageUrl = f.dataUrl

      this.upload(f)
    },
    async read () {
      if (this.$store.state.user.photoURL) {
        this.imageUrl = this.$store.state.user.photoURL
      }

      const r = await this.$firebase.firestore().collection('users').doc(this.$store.state.user.uid).get()
      if (r.exists) {
        this.form = r.data()
      }
    },
    upload (file) {
      const fileName = new Date().getTime()
      const ext = '.' + file.info.name.split('.')[1]
      try {
        const storageRef = this.$firebase.storage().ref()

        this.uploadTask = storageRef.child('profiles/' + this.$store.state.user.uid + '/' + fileName + ext).putString(file.dataUrl, 'data_url')
        this.firebaseImageName = fileName + ext
        this.uploading = true
      } catch (e) {
        console.log(e.message)
      }
    },
    // changePw () {
    //   const email = this.$firebase.auth().currentUser.email

    //   const auth = this.$firebase.auth()
    //   auth.sendPasswordResetEmail(email).then(() => {
    //     this.$swal.fire({
    //       icon: 'success',
    //       title: '이메일을 확인하고, 패스워드를 변경하세요'
    //     })
    //   }).catch(() => {
    //     // console.log(error)
    //     this.$swal.fire({
    //       icon: 'error',
    //       title: '패스워드 변경을 처리할 수 없습니다.'
    //     })
    //   })
    // },
    updatePhoto () {
      try {
        const user = this.$firebase.auth().currentUser

        user.updateProfile({
          photoURL: this.firebaseImageUrl
        }).then(r => {
          this.$store.dispatch('getUser', user)
        })

        this.$firebase.firestore().collection('users').doc(this.$store.state.user.uid).update({
          photoName: this.firebaseImageName
        }).then(() => {
          if (this.form.photoName) {
            this.delStorageFile()
          }
        })
      } catch (e) {
        console.log(e.message)
      }
    },
    async delStorageFile () {
      // 'profiles/' + this.$store.state.user.uid + '/' + fileName + ext).putString(file.dataUrl, 'data_url'
      const fileName = 'profiles/' + this.$store.state.user.uid + '/' + this.form.photoName
      const storageRef = await this.$firebase.storage().ref().child(fileName)
      storageRef.delete().then(() => {
        // console.log('delStorageFile', fileName)
      }).catch(e => {
        console.log(e.message)
      })
    }
  }
}
</script>
